/* eslint no-param-reassign: 0 */

import getUuid from 'widget/helpers/uuid';
import { MESSAGE_STATUS, MESSAGE_TYPE } from 'shared/constants/messages';
import { format } from 'date-fns';
import { es, enUS } from 'date-fns/locale';
import UTIF from 'utif';

export default () => {
  if (!Array.prototype.last) {
    Object.assign(Array.prototype, {
      last() {
        return this[this.length - 1];
      },
    });
  }
};

export const isEmptyObject = obj =>
  Object.keys(obj).length === 0 && obj.constructor === Object;

export const isJSONValid = value => {
  try {
    JSON.parse(value);
  } catch (e) {
    return false;
  }
  return true;
};

export const getTypingUsersText = (users = [], translate) => {
  const count = users.length;
  if (count === 1) {
    const [user] = users;
    return translate.t('CONVERSATION.PARTICIPANT-IS-TYPING', { username: user.name});
  }

  if (count === 2) {
    const [first, second] = users;
    return translate.t('CONVERSATION.PARTICIPANTS-ARE-TYPING', {
      firstName: first.name,
      secondName: second.name,
    });
  }

  const [user] = users;
  const rest = users.length - 1;
  return translate.t('CONVERSATION.PARTICIPANTS-AND-OTHERS-ARE-TYPING', {
    username: user.name,
    rest,
  });
};

export const createPendingMessage = data => {
  const timestamp = Math.floor(new Date().getTime() / 1000);
  const tempMessageId = getUuid();
  const { message, file } = data;
  const tempAttachments = [{ id: tempMessageId }];
  const pendingMessage = {
    ...data,
    content: message || null,
    id: tempMessageId,
    echo_id: tempMessageId,
    status: MESSAGE_STATUS.PROGRESS,
    created_at: timestamp,
    message_type: MESSAGE_TYPE.OUTGOING,
    conversation_id: data.conversationId,
    attachments: file ? tempAttachments : null,
  };

  return pendingMessage;
};

export const convertToAttributeSlug = text => {
  return text
    .toLowerCase()
    .replace(/[^\w ]+/g, '')
    .replace(/ +/g, '_');
};

export const convertToCategorySlug = text => {
  return text
    .toLowerCase()
    .replace(/[^\w ]+/g, '')
    .replace(/ +/g, '-');
};

export const convertToPortalSlug = text => {
  return text
    .toLowerCase()
    .replace(/[^\w ]+/g, '')
    .replace(/ +/g, '-');
};

/**
 * Devuelve el primer carácter de la cadena, en mayúscula, concatenado con el resto de la cadena.
 * @param {string} str - La cadena para escribir en mayúsculas.
 * @returns { string } La primera letra de la cadena está en mayúscula y el resto de la cadena está
 * devuelto.
 */
export const capitalizeFirstLetter = str => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

/**
 * Devuelve un string con la fecha, día de la semana u hora dependiendo de la diferencia entre el
 * fecha actual y la fecha pasada como parámetro
 * @param {date} currentDate - La fecha y hora actuales.
 * @param {date} previewDate - La fecha del mensaje anterior.
 * @param {string} yesterdayTranslate - La traducción de la palabra "ayer" en el idioma que desea utilizar.
 * @param {string} locale - La configuración regional del usuario.
 */
export const getTimeFormat = (
  currentDate,
  previewDate,
  yesterdayTranslate,
  locale
) => {
  const DAY_IN_MILLISECONDS = 60 * 60 * 24 * 1000;
  let current = format(currentDate, 'dd/MM/yyyy');
  let preview = format(previewDate, 'dd/MM/yyyy');

  // Hoy
  if (current === preview) {
    return format(previewDate, 'hh:mm aaa');
  }

  let dayDifference = Math.round(
    (currentDate - previewDate) / DAY_IN_MILLISECONDS
  );

  // Ayer
  if (dayDifference === 1) {
    return yesterdayTranslate;
  }
  // Menor a una semana
  if (dayDifference < 8) {
    return capitalizeFirstLetter(
      format(previewDate, 'EEEE', {
        locale: locale === 'es' ? es : enUS,
      })
    );
  }

  // Fecha completa en formato dd/MM/yyyy
  return preview;
};

/**
 * Comprueba si la contraseña contiene al menos una mayúscula, una minúscula, un número y un símbolo
 * @param {string} value - El valor del campo de entrada.
 * @returns El mensaje de error o nulo si esta correcto.
 */
export const checkPasswordValidity = (password, translate) => {
  const rules = [
    [
      /(?=.*[A-Z]).*/,
      translate.t('SET_NEW_PASSWORD.MESSAGES_ERROR.TEXT_CAPITAL_LETTER'),
    ],
    [
      /(?=.*[a-z]).*/,
      translate.t('SET_NEW_PASSWORD.MESSAGES_ERROR.TEXT_LOWERCASE_LETTER'),
    ],
    [
      /(?=.*[0-9]).*/,
      translate.t('SET_NEW_PASSWORD.MESSAGES_ERROR.TEXT_A_NUMBER'),
    ],
    [
      /(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹]).*/,
      translate.t('SET_NEW_PASSWORD.MESSAGES_ERROR.TEXT_A_SPECIAL_CHARACTER'),
    ],
  ];

  const invalidChars = [];
  rules.forEach(([regex, charName]) => {
    if (!regex.test(password)) {
      invalidChars.push(charName);
    }
  });

  if (invalidChars.length === 0) {
    return '';
  } else if (invalidChars.length === 1) {
    return translate.t('SET_NEW_PASSWORD.MESSAGES_ERROR.INVALID_ALERT_1', {
      message: invalidChars[0],
    });
  } else if (invalidChars.length === 2) {
    return translate.t('SET_NEW_PASSWORD.MESSAGES_ERROR.INVALID_ALERT_2', {
      message1: invalidChars[0],
      message2: invalidChars[1],
    });
  } else if (invalidChars.length === 3) {
    return translate.t('SET_NEW_PASSWORD.MESSAGES_ERROR.INVALID_ALERT_3', {
      message1: invalidChars[0],
      message2: invalidChars[1],
      message3: invalidChars[2],
    });
  } else if (invalidChars.length === 4) {
    return translate.t('SET_NEW_PASSWORD.MESSAGES_ERROR.INVALID_ALERT_4', {
      message1: invalidChars[0],
      message2: invalidChars[1],
      message3: invalidChars[2],
      message4: invalidChars[3],
    });
  }
}

/* La sentencia `export const urlThumbnail` define una variable constante llamada `urlThumbnail` y
asignándole el valor de `'https://cdn.intertelconversa.com'`. A continuación, esta variable se exporta y
se puede importar y utilizar en otros módulos. */
export const urlThumbnail = 'https://cdn.intertelconversa.com';

/**
 * La función serialNumber rellena un número dado con ceros a la izquierda y lo devuelve como una cadena con un prefijo de "IC".
 * @param {number} num - El número de serie que debe formatearse como una cadena con ceros a la izquierda.
 * @param {number} size - El parámetro `size` en la función `serialNumber` es la longitud deseada del
 * número de serie. Si el parámetro `num` (que se convierte en una cadena) es más corto que `size`,
 * la función agregará ceros iniciales para que tenga la longitud deseada.
 * @returns {string} una cadena que comienza con "IC" seguida por el número de entrada convertido a una cadena y
 * rellenado con ceros a la izquierda para que coincida con el tamaño de entrada.
 */
export const serialNumber = (num, size) => {
  num = num.toString();
  while (num.length < size) num = '0' + num;
  return `IC${num}`;
};
/** Reintentos */
const RETRIES_COUNT = 30;
/**
 * La función `getFile` obtiene un archivo de una URL determinada y devuelve sus datos como un búfer de matriz.
 * @param {string} url - Ruta del archivo.
 * @param {number} retries - numero de reintentos.
 * @returns La función `getFile` devuelve un objeto `ArrayBuffer`, que representa un objeto genérico,
 * búfer de datos binarios de longitud fija.
 */
export const getBytesTiff = async (url, retries = RETRIES_COUNT) => {
  const res = await fetch(url);
  if (res.ok) {
    const blob = await res.blob();
    const file = new File([blob], 'file', { type: 'image/tiff' });
    const bytes = await file.arrayBuffer();
    return bytes;
  }
  if (retries > 0) {
    return getBytesTiff(url, retries - 1);
  }
  throw new Error(res.status);
};

/**
 * Obtiene los bytes de un archivo SVG desde una URL.
 * @param {string} url - La URL del archivo SVG.
 * @param {number} retries - (Opcional) El número de reintentos en caso de fallo. Valor predeterminado: RETRIES_COUNT.
 * @returns {Promise<ArrayBuffer>} Los bytes del archivo SVG en formato ArrayBuffer.
 * @throws {Error} Si la solicitud no es exitosa después de agotar los reintentos.
 */
export const getBytesSVG = async (url, retries = RETRIES_COUNT) => {
  const res = await fetch(url);
  if (res.ok) {
    const text = await res.text();
    const encoder = new TextEncoder();
    const bytes = encoder.encode(text);
    return bytes;
  }
  if (retries > 0) {
    return getBytesSVG(url, retries - 1);
  }
  throw new Error(res.status);
};


/**
 * La función `getBytesImg` obtiene una imagen de una URL determinada y devuelve sus datos como un búfer de matriz.
 * @param {string} url - Ruta del archivo.
 * @param {number} retries - numero de reintentos.
 * @returns La función `getBytesImg` devuelve un string de la ruta de la imagen.
 */
export const getBytesImg = async (url, retries = RETRIES_COUNT) => {
  const res = await fetch(url);
  if (res.ok) {
    const imageBlob = await res.blob();
    const imageObjectURL = URL.createObjectURL(imageBlob);
    return imageObjectURL;
  }
  if (retries > 0) {
    return getBytesImg(url, retries - 1);
  }
  throw new Error(res.status);
};
/**
 * La función decodifica una imagen TIFF a partir de bytes y devuelve sus valores RGBA, ancho y alto.
 * @param {array} bytes - Los bytes de la imagen tiff.
 * @returns La función `decodeImage` devuelve un objeto con tres propiedades: `rgba`, `imageWidth`,
 * y `altura de la imagen`.
 */
export const decodeImage = bytes => {
  const ifds = UTIF.decode(bytes);
  let vsns = ifds;
  let ma = 0;
  let page = vsns[0];
  if (ifds[0].subIFD) vsns = vsns.concat(ifds[0].subIFD);
  for (let i = 0; i < vsns.length; i += 1) {
    const img = vsns[i];
    if (img.t258 !== null && img.t258.length >= 3) {
      const ar = img.t256 * img.t257;
      if (ar > ma) {
        ma = ar;
        page = img;
      }
    }
  }
  UTIF.decodeImage(bytes, page, ifds);
  const rgba = UTIF.toRGBA8(page);
  const imageWidth = page.width;
  const imageHeight = page.height;
  return {
    rgba,
    imageWidth,
    imageHeight,
  };
};
/**
 * La función construye un elemento de lienzo con dimensiones especificadas y valores RGBA y lo agrega a
 * un elemento HTML especificado.
 * @param {string} imageContent - El ID del elemento HTML al que se adjuntará el lienzo.
 * @param {number} imageWidth - El ancho del lienzo en píxeles.
 * @param {number} imageHeight - La altura del elemento de lienzo que se creará.
 * @param {array} rgba - El parámetro `rgba` es una matriz de enteros que representan los colores rojo, verde, azul
 * y valores alfa de cada píxel en el lienzo.
 * @param {string} width - El ancho del elemento canvas en unidades CSS. El valor predeterminado es '32rem'.
 */
export function renderImage(imageContent, decode, width = '32rem') {
  const { imageWidth, imageHeight, rgba } = decode;
  const cnv = document.createElement('canvas');
  cnv.width = imageWidth;
  cnv.height = imageHeight;
  cnv.style.width = width;
  const ctx = cnv.getContext('2d');
  const imgd = ctx.createImageData(imageWidth, imageHeight);
  for (let i = 0; i < rgba.length; i += 1) {
    imgd.data[i] = rgba[i];
  }
  ctx.putImageData(imgd, 0, 0);
  const image = document.getElementById(imageContent);
  image.removeAttribute('class');
  const parent = image.parentElement;
  parent.removeAttribute('class');
  image.setAttribute('src', cnv.toDataURL());
}

/**
 * Función para obtener horas de diferencia por zona horaria
 * @returns Numero de horas de diferencia horaria
 */
export const getTimeOffset = () => {
  const timezoneOffset = new Date().getTimezoneOffset();

  return timezoneOffset === 0 ? timezoneOffset : -timezoneOffset / 60;
};
