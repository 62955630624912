<template>
  <div>
    <cisco v-if="inbox.pbx === 'cisco_call_manager'" :inbox="inbox" />
    <api v-if="inbox.pbx === 'api'" :inbox="inbox" />
    <trescx v-if="inbox.pbx === '3cx'" :inbox="inbox" />
    <grandstream v-if="inbox.pbx === 'grandstream'" :inbox="inbox" />
  </div>
</template>

<script>
import Cisco from './cisco';
import Api from './api';
import Trescx from './3cx';
import Grandstream from './grandstream';

export default {
  components: {
    Cisco,
    Api,
    Trescx,
    Grandstream,
  },
  props: {
    inbox: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
