export default {
  methods: {
    showAlert(message, action) {
      bus.$emit('newToastMessage', message, action);
    },
    /**
     * Muestra toast de tipo success
     */
    showAlertSuccess(message) {
      bus.$emit('newToastMessage', message, undefined, 'success');
    },
    /**
     * Muestra toast de tipo error
     */
    showAlertError(message) {
      bus.$emit('newToastMessage', message, undefined, 'error');
    },
  },
};
