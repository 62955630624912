<template>
  <div>
    <settings-section
      :title="$t('INBOX_MGMT.SETTINGS_POPUP.INBOX_UPDATE_TITLE')"
      :sub-title="$t('INBOX_MGMT.SETTINGS_POPUP.INBOX_UPDATE_SUB_TEXT')"
    >
      <woot-avatar-uploader
        :label="$t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_AVATAR.LABEL')"
        :src="avatarUrl"
        class="settings-item intertel--uc--avatar"
        delete-avatar
        @change="handleImageUpload"
        @onAvatarDelete="handleAvatarDelete"
      />
      <woot-input-channel-name
        v-model.trim="selectedInboxName"
        class="medium-9 columns"
        :class="{ error: $v.selectedInboxName.$error }"
        :label="$t('INBOX_MGMT.ADD.CHANNEL_NAME.LABEL')"
        :placeholder="$t('INBOX_MGMT.ADD.CHANNEL_NAME.PLACEHOLDER')"
        @blur="$v.selectedInboxName.$touch"
      />
    </settings-section>
    <settings-section
      :title="$t('INBOX_MGMT.ADD.INTERTEL_UC_CHANNEL.PBX.TITLE')"
      :sub-title="$t('INBOX_MGMT.ADD.INTERTEL_UC_CHANNEL.PBX.SUBTITLE')"
      class="pbx--type--section"
    >
      <label class="medium-9 columns">
        {{ $t('INBOX_MGMT.ADD.INTERTEL_UC_CHANNEL.PBX_TYPES.TITLE') }}
        <select
          v-model="pbx"
          class="pbx--type--select"
          :class="{ 'pbx-not-selected': pbx == '' }"
          disabled
        >
          <option value="" selected disabled class="pbx-not-selected">
            {{
              $t('INBOX_MGMT.ADD.INTERTEL_UC_CHANNEL.PBX_TYPES.NOT_SELECTED')
            }}
          </option>
          <option value="3cx" class="pbx-type-option">
            {{ $t('INBOX_MGMT.ADD.INTERTEL_UC_CHANNEL.PBX_TYPES.3CX') }}
          </option>
          <option value="cisco_call_manager" class="pbx-type-option">
            {{
              $t(
                'INBOX_MGMT.ADD.INTERTEL_UC_CHANNEL.PBX_TYPES.CISCO_CALL_MANAGER'
              )
            }}
          </option>
          <option value="api" class="pbx-type-option">
            {{ $t('INBOX_MGMT.ADD.INTERTEL_UC_CHANNEL.PBX_TYPES.API') }}
          </option>
          <option value="grandstream" class="pbx-type-option">
            {{ $t('INBOX_MGMT.ADD.INTERTEL_UC_CHANNEL.PBX_TYPES.GRANDSTREAM') }}
          </option>
        </select>
      </label>
    </settings-section>
    <settings-section class="pbx--url--section">
      <woot-input
        v-model.trim="pbxUrl"
        class="medium-9 columns intertel--uc--input"
        :class="{
          error: $v.pbxUrl.$error || connectionStatus === false,
        }"
        :label="$t('INBOX_MGMT.ADD.INTERTEL_UC_CHANNEL.PBX_URL.TITLE')"
        :placeholder="
          $t('INBOX_MGMT.ADD.INTERTEL_UC_CHANNEL.PBX_URL.PLACEHOLDER')
        "
        error=""
        @input="onChangePbxData"
        @blur="$v.pbxUrl.$touch"
      />
      <woot-input
        v-model.trim="pbxUser"
        class="medium-9 columns intertel--uc--input"
        :class="{
          error: $v.pbxUser.$error || connectionStatus === false,
        }"
        :label="$t('INBOX_MGMT.ADD.INTERTEL_UC_CHANNEL.PBX_USER.TITLE')"
        :placeholder="
          $t('INBOX_MGMT.ADD.INTERTEL_UC_CHANNEL.PBX_USER.PLACEHOLDER')
        "
        error=""
        @input="onChangePbxData"
        @blur="$v.pbxUser.$touch"
      />
      <woot-input
        v-model.trim="pbxPassword"
        type="password"
        class="medium-9 columns intertel--uc--input"
        :class="{
          error: $v.pbxPassword.$error || connectionStatus === false,
        }"
        :label="$t('INBOX_MGMT.ADD.INTERTEL_UC_CHANNEL.PBX_PASSWORD.TITLE')"
        :placeholder="
          $t('INBOX_MGMT.ADD.INTERTEL_UC_CHANNEL.PBX_PASSWORD.PLACEHOLDER')
        "
        error=""
        @input="onChangePbxData"
        @blur="$v.pbxPassword.$touch"
      />
      <woot-button
        :is-loading="isCheckConnection"
        type="button"
        :variant="getVariantClass"
        :is-disabled="
          pbxUrl === '' ||
            pbxUser === '' ||
            pbxPassword === '' ||
            connectionStatus
        "
        class="button success"
        @click="checkConnection"
      >
        {{ $t('INBOX_MGMT.ADD.INTERTEL_UC_CHANNEL.TEST_CONNECTION_BUTTON') }}
      </woot-button>
    </settings-section>
    <settings-section class="button--section">
      <woot-submit-button
        type="submit"
        :disabled="
          $v.selectedInboxName.$invalid ||
            $v.pbxUrl.$invalid ||
            $v.pbxUser.$invalid ||
            $v.pbxPassword.$invalid ||
            !connectionStatus
        "
        :button-text="$t('INBOX_MGMT.SETTINGS_POPUP.UPDATE')"
        :loading="isUpdating"
        @click="updateInbox"
      />
    </settings-section>
  </div>
</template>

<script>
import { required, maxLength } from 'vuelidate/lib/validators';
import alertMixin from 'shared/mixins/alertMixin';
import inboxMixin from 'shared/mixins/inboxMixin';
import SettingsSection from '../../../../../components/SettingsSection';

export default {
  components: {
    SettingsSection,
  },
  mixins: [alertMixin, inboxMixin],
  props: {
    inbox: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  data() {
    return {
      avatarFile: null,
      avatarUrl: this.inbox.avatar_url,
      selectedInboxName: this.inbox.name,
      isUpdating: false,
      pbx: this.inbox.pbx,
      pbxUrl: this.inbox.additional_attributes.pbx_url,
      pbxUser: this.inbox.additional_attributes.pbx_user,
      pbxPassword: this.inbox.additional_attributes.pbx_password,
      connectionStatus: true,
      isCheckConnection: false,
    };
  },
  computed: {
    /**
     * Obtenemos la clase para el boton de comprobar conexion
     */
    getVariantClass() {
      return 'button--check';
    },
  },
  methods: {
    /**
     * Funcion para actualizar canal
     */
    async updateInbox() {
      this.isUpdating = true;
      try {
        const payload = {
          id: this.inbox.id,
          name: this.selectedInboxName,
          channel: {
            pbx: this.pbx,
            additional_attributes: {
              pbx_url: this.pbxUrl,
              pbx_user: this.pbxUser,
              pbx_password: this.pbxPassword,
            },
          },
        };

        if (this.avatarFile) {
          payload.avatar = this.avatarFile;
        }

        await this.$store.dispatch('inboxes/updateInbox', payload);
        this.showAlert(this.$t('INBOX_MGMT.EDIT.API.SUCCESS_MESSAGE'));
      } catch (error) {
        this.showAlert(
          error.message || this.$t('INBOX_MGMT.EDIT.API.ERROR_MESSAGE')
        );
      }
      this.isUpdating = false;
    },
    /**
     * Probamos conexion al servidor del conmutador
     */
    async checkConnection() {
      this.isCheckConnection = true;
      try {
        const response = await this.$store.dispatch('inboxes/checkConnection', {
          pbx: this.pbx,
          pbx_url: this.pbxUrl,
          pbx_user: this.pbxUser,
          pbx_password: this.pbxPassword,
        });

        if (response.status === 200) {
          this.connectionStatus = true;
          this.showAlertSuccess(
            this.$t(
              'INBOX_MGMT.ADD.INTERTEL_UC_CHANNEL.TEST_CONNECTION_SUCCESS'
            )
          );
        } else {
          this.connectionStatus = false;
          this.showAlertError(
            this.$t('INBOX_MGMT.ADD.INTERTEL_UC_CHANNEL.TEST_CONNECTION_ERROR')
          );
        }
      } catch (error) {
        this.connectionStatus = false;
        this.showAlertError(
          this.$t('INBOX_MGMT.ADD.INTERTEL_UC_CHANNEL.TEST_CONNECTION_ERROR')
        );
      }

      this.isCheckConnection = false;
    },
    /**
     * Establecemos el status de la conexion con el pbx como undefined para que se vuelva a probar conexion debido de informacion
     */
    async onChangePbxData() {
      this.connectionStatus = undefined;
    },
    /**
     * Establece los valores para el avatar
     */
    handleImageUpload({ file, url }) {
      this.avatarFile = file;
      this.avatarUrl = url;
    },
    /**
     * Elimina el avatar configurado
     */
    async handleAvatarDelete() {
      try {
        await this.$store.dispatch('inboxes/deleteInboxAvatar', this.inbox.id);
        this.avatarFile = null;
        this.avatarUrl = '';
        this.showAlert(this.$t('INBOX_MGMT.DELETE.API.AVATAR_SUCCESS_MESSAGE'));
      } catch (error) {
        this.showAlert(
          error.message
            ? error.message
            : this.$t('INBOX_MGMT.DELETE.API.AVATAR_ERROR_MESSAGE')
        );
      }
    },
  },
  validations: {
    selectedInboxName: { required, maxLength: maxLength(30) },
    pbxUrl: {
      required,
    },
    pbxUser: {
      required,
    },
    pbxPassword: {
      required,
    },
  },
};
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/variables';
@import '~dashboard/assets/scss/mixins';

.pbx--type--section {
  padding-top: 72px;
}

.pbx--url--section {
  padding-top: 4px;
}

.button--section {
  padding-top: 40px;
}

.settings--section {
  border-bottom: 0;
  padding-bottom: 0;
}

.settings-item.intertel--uc--avatar {
  margin-bottom: 24px;
}

.pbx-not-selected {
  color: $conversa2-neutral-300-color;
}

.pbx--type--select:disabled {
  color: var(--conversa2-neutral-500-color);
  background: var(--conversa2-neutral-100-color);
  border: 1px solid var(--Neutral-Neutral-200);
}

.pbx-type-option {
  color: $color-body;
}

.intertel--uc--input {
  padding-bottom: 4px;
}

.button--check:hover {
  background-color: #d75400;
}
</style>
