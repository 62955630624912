<template>
  <div>
    <settings-section
      :title="$t('INBOX_MGMT.SETTINGS_POPUP.INBOX_UPDATE_TITLE')"
      :sub-title="$t('INBOX_MGMT.SETTINGS_POPUP.INBOX_UPDATE_SUB_TEXT')"
    >
      <woot-avatar-uploader
        :label="$t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_AVATAR.LABEL')"
        :src="avatarUrl"
        class="settings-item intertel--uc--avatar"
        delete-avatar
        @change="handleImageUpload"
        @onAvatarDelete="handleAvatarDelete"
      />
      <woot-input-channel-name
        v-model.trim="selectedInboxName"
        class="medium-9 columns settings-item"
        :class="{ error: $v.selectedInboxName.$error }"
        :label="$t('INBOX_MGMT.ADD.CHANNEL_NAME.LABEL')"
        :placeholder="$t('INBOX_MGMT.ADD.CHANNEL_NAME.PLACEHOLDER')"
        @blur="$v.selectedInboxName.$touch"
      />
    </settings-section>
    <settings-section
      :title="$t('INBOX_MGMT.ADD.INTERTEL_UC_CHANNEL.PBX.TITLE')"
      :sub-title="$t('INBOX_MGMT.ADD.INTERTEL_UC_CHANNEL.PBX.SUBTITLE')"
      class="pbx--type--section"
    >
      <label class="medium-9 columns settings-item">
        {{ $t('INBOX_MGMT.ADD.INTERTEL_UC_CHANNEL.PBX_TYPES.TITLE') }}
        <select
          v-model="pbx"
          class="pbx--type--select"
          :class="{ 'pbx-not-selected': pbx == '' }"
          disabled
        >
          <option value="" selected disabled class="pbx-not-selected">
            {{
              $t('INBOX_MGMT.ADD.INTERTEL_UC_CHANNEL.PBX_TYPES.NOT_SELECTED')
            }}
          </option>
          <option value="cisco_call_manager" class="pbx-type-option">
            {{
              $t(
                'INBOX_MGMT.ADD.INTERTEL_UC_CHANNEL.PBX_TYPES.CISCO_CALL_MANAGER'
              )
            }}
          </option>
          <option value="api" class="pbx-type-option">
            {{ $t('INBOX_MGMT.ADD.INTERTEL_UC_CHANNEL.PBX_TYPES.API') }}
          </option>
        </select>
      </label>
    </settings-section>
    <settings-section
      :title="$t('INBOX_MGMT.ADD.INTERTEL_UC_CHANNEL.WEBHOOK_CALL.TITLE')"
      :sub-title="
        $t('INBOX_MGMT.ADD.INTERTEL_UC_CHANNEL.WEBHOOK_CALL.SUBTITLE')
      "
      class="webhook--section"
    >
      <woot-input
        v-model.trim="webhookCall"
        class="medium-9 columns settings-item intertel--uc--webhook"
        :class="{ error: $v.webhookCall.$error }"
        :label="$t('INBOX_MGMT.ADD.INTERTEL_UC_CHANNEL.WEBHOOK_CALL.TITLE')"
        :placeholder="
          $t('INBOX_MGMT.ADD.INTERTEL_UC_CHANNEL.WEBHOOK_CALL.PLACEHOLDER')
        "
        :error="
          $v.webhookCall.$error
            ? $t('INBOX_MGMT.ADD.INTERTEL_UC_CHANNEL.WEBHOOK_CALL.ERROR')
            : ''
        "
        @blur="$v.webhookCall.$touch"
      />
      <div class="settings-item checkbox--list">
        <label>
          {{
            $t('INBOX_MGMT.ADD.INTERTEL_UC_CHANNEL.WEBHOOK_CALL.EVENT_TITLE')
          }}
        </label>
        <div
          v-for="event in supportedWebhookEvents"
          :key="event"
          :disabled="$v.webhookCall.$invalid"
          class="checkbox--item"
        >
          <input
            :id="event"
            v-model="events"
            type="checkbox"
            :value="event"
            name="events"
          />
          <span
            class="fs-small checkbox--label"
            :disabled="$v.webhookCall.$invalid"
          >
            {{ `${getEventLabel(event)}` }}
          </span>
        </div>
      </div>
    </settings-section>
    <settings-section class="button--section">
      <woot-submit-button
        type="submit"
        :disabled="
          $v.selectedInboxName.$invalid ||
            $v.webhookCall.$invalid ||
            $v.events.$invalid
        "
        :button-text="$t('INBOX_MGMT.SETTINGS_POPUP.UPDATE')"
        :loading="isUpdating"
        @click="updateInbox"
      />
    </settings-section>
  </div>
</template>

<script>
import { additionalUrlValidators } from 'shared/helpers/Validators';
import {
  required,
  maxLength,
  url as urlValidator,
} from 'vuelidate/lib/validators';
import alertMixin from 'shared/mixins/alertMixin';
import inboxMixin from 'shared/mixins/inboxMixin';
import SettingsSection from '../../../../../components/SettingsSection';

/**
 * Eventos soportados por el webhook_call para Intertel UC
 */
const SUPPORTED_WEBHOOK_EVENTS = ['call.started', 'call.ended'];

export default {
  components: {
    SettingsSection,
  },
  mixins: [alertMixin, inboxMixin],
  props: {
    inbox: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  data() {
    return {
      avatarFile: null,
      avatarUrl: this.inbox.avatar_url,
      selectedInboxName: this.inbox.name,
      isUpdating: false,
      pbx: this.inbox.pbx,
      webhookCall: this.inbox.webhook_call,
      events: this.inbox.events,
      supportedWebhookEvents: SUPPORTED_WEBHOOK_EVENTS,
    };
  },
  methods: {
    /**
     * Funcion para actualizar canal
     */
    async updateInbox() {
      this.isUpdating = true;
      try {
        const payload = {
          id: this.inbox.id,
          name: this.selectedInboxName,
          channel: {
            pbx: this.pbx,
            webhook_call: this.webhookCall,
          },
        };

        if (this.avatarFile) {
          payload.avatar = this.avatarFile;
        }

        await this.$store.dispatch('inboxes/updateInbox', payload);

        const payloadEvents = {
          id: this.inbox.id,
          formData: false,
          channel: {
            events: this.events,
          },
        };

        await this.$store.dispatch('inboxes/updateInbox', payloadEvents);
        this.showAlert(this.$t('INBOX_MGMT.EDIT.API.SUCCESS_MESSAGE'));
      } catch (error) {
        this.showAlert(
          error.message || this.$t('INBOX_MGMT.EDIT.API.ERROR_MESSAGE')
        );
      }
      this.isUpdating = false;
    },
    /**
     * Establece los valores para el avatar
     */
    handleImageUpload({ file, url }) {
      this.avatarFile = file;
      this.avatarUrl = url;
    },
    /**
     * Elimina el avatar configurado
     */
    async handleAvatarDelete() {
      try {
        await this.$store.dispatch('inboxes/deleteInboxAvatar', this.inbox.id);
        this.avatarFile = null;
        this.avatarUrl = '';
        this.showAlert(this.$t('INBOX_MGMT.DELETE.API.AVATAR_SUCCESS_MESSAGE'));
      } catch (error) {
        this.showAlert(
          error.message
            ? error.message
            : this.$t('INBOX_MGMT.DELETE.API.AVATAR_ERROR_MESSAGE')
        );
      }
    },
  },
  validations: {
    selectedInboxName: { required, maxLength: maxLength(30) },
    webhookCall: {
      required,
      urlValidator,
      additionalUrlValidators,
    },
    events: {
      required,
    },
  },
};
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/variables';
@import '~dashboard/assets/scss/mixins';

.pbx--type--section {
  padding-top: 60px;
}

.webhook--section {
  padding-top: 24px;
}

.button--section {
  padding-top: 40px;
}

.settings--section {
  border-bottom: 0;
  padding-bottom: 0;
}

.settings-item.intertel--uc--avatar {
  margin-bottom: 24px;
}

.pbx-not-selected {
  color: $conversa2-neutral-300-color;
}

.pbx--type--select:disabled {
  color: var(--conversa2-neutral-500-color);
  background: var(--conversa2-neutral-100-color);
  border: 1px solid var(--Neutral-Neutral-200);
}

.pbx-type-option {
  color: $color-body;
}

.intertel--uc--webhook {
  padding-bottom: 4px;
}

.checkbox--list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  padding-bottom: 0;

  .checkbox--item {
    display: flex;
    align-items: center;
    gap: 8px;
    align-self: stretch;

    input[type='checkbox'] {
      height: 20px;
      width: 20px;
    }
  }

  label,
  span {
    color: var(--conversa2-neutral-800-color);
    font-size: var(--font-size-small);
    line-height: var(--font-size-medium);
  }

  div[disabled] {
    pointer-events: none;
  }

  span[disabled] {
    pointer-events: none;
    color: var(--conversa2-neutral-300-color);
  }
}
</style>
