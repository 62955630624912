<template>
  <div class="wizard-body small-9 columns">
    <page-header
      :header-title="$t('INBOX_MGMT.ADD.INTERTEL_UC_CHANNEL.TITLE')"
      :header-content="$t('INBOX_MGMT.ADD.INTERTEL_UC_CHANNEL.DESC')"
    />
    <form class="row" @submit.prevent="createChannel()">
      <div class="medium-8 columns">
        <label :class="{ error: $v.channelName.$error }">
          {{ $t('INBOX_MGMT.ADD.INTERTEL_UC_CHANNEL.CHANNEL_NAME.LABEL') }}
          <woot-input-channel-name
            v-model.trim="channelName"
            type="text"
            :placeholder="
              $t('INBOX_MGMT.ADD.INTERTEL_UC_CHANNEL.CHANNEL_NAME.PLACEHOLDER')
            "
            @blur="$v.channelName.$touch"
          />
        </label>
      </div>

      <div class="medium-8 columns section--item">
        <label>
          {{ $t('INBOX_MGMT.ADD.INTERTEL_UC_CHANNEL.PBX_TYPES.TITLE') }}
          <select
            v-model="pbx"
            class="pbx--type--select"
            :class="{ 'pbx-not-selected': pbx == '' }"
          >
            <option value="" selected disabled class="pbx-not-selected">
              {{
                $t('INBOX_MGMT.ADD.INTERTEL_UC_CHANNEL.PBX_TYPES.NOT_SELECTED')
              }}
            </option>
            <option value="3cx" class="pbx-type-option">
              {{ $t('INBOX_MGMT.ADD.INTERTEL_UC_CHANNEL.PBX_TYPES.3CX') }}
            </option>
            <option value="cisco_call_manager" class="pbx-type-option">
              {{
                $t(
                  'INBOX_MGMT.ADD.INTERTEL_UC_CHANNEL.PBX_TYPES.CISCO_CALL_MANAGER'
                )
              }}
            </option>
            <option value="api" class="pbx-type-option">
              {{ $t('INBOX_MGMT.ADD.INTERTEL_UC_CHANNEL.PBX_TYPES.API') }}
            </option>
            <option value="grandstream" class="pbx-type-option">
              {{
                $t('INBOX_MGMT.ADD.INTERTEL_UC_CHANNEL.PBX_TYPES.GRANDSTREAM')
              }}
            </option>
          </select>
        </label>
      </div>
      <!-- SECCION PARA API -->
      <div v-if="pbx === 'api'" class="medium-8 columns section--item">
        <woot-input
          v-model.trim="webhookCall"
          class="intertel--uc--input"
          :class="{ error: $v.webhookCall.$error }"
          :label="$t('INBOX_MGMT.ADD.INTERTEL_UC_CHANNEL.WEBHOOK_CALL.TITLE')"
          :placeholder="
            $t('INBOX_MGMT.ADD.INTERTEL_UC_CHANNEL.WEBHOOK_CALL.PLACEHOLDER')
          "
          :error="
            $v.webhookCall.$error
              ? $t('INBOX_MGMT.ADD.INTERTEL_UC_CHANNEL.WEBHOOK_CALL.ERROR')
              : ''
          "
          @blur="$v.webhookCall.$touch"
        />
      </div>

      <div v-if="pbx === 'api'" class="medium-8 columns">
        <div class="checkbox--list">
          <label>
            {{
              $t('INBOX_MGMT.ADD.INTERTEL_UC_CHANNEL.WEBHOOK_CALL.EVENT_TITLE')
            }}
          </label>
          <div
            v-for="event in supportedWebhookEvents"
            :key="event"
            :disabled="$v.webhookCall.$invalid"
            class="checkbox--item"
          >
            <input
              :id="event"
              v-model="events"
              type="checkbox"
              :value="event"
              name="events"
            />
            <span
              class="fs-small checkbox--label"
              :disabled="$v.webhookCall.$invalid"
            >
              {{ `${getEventLabel(event)}` }}
            </span>
          </div>
        </div>
      </div>
      <!-- SECCION PARA 3CX Y GRANDSTREAM -->
      <div
        v-if="pbx === '3cx' || pbx === 'grandstream'"
        class="medium-8 columns section--item"
      >
        <woot-input
          v-model.trim="pbxUrl"
          class="intertel--uc--input"
          :class="{
            error: $v.pbxUrl.$error || connectionStatus === false,
          }"
          :label="$t('INBOX_MGMT.ADD.INTERTEL_UC_CHANNEL.PBX_URL.TITLE')"
          :placeholder="
            $t('INBOX_MGMT.ADD.INTERTEL_UC_CHANNEL.PBX_URL.PLACEHOLDER')
          "
          error=""
          @input="onChangePbxData"
          @blur="$v.pbxUrl.$touch"
        />
      </div>
      <div v-if="pbx === 'grandstream'" class="medium-8 columns">
        <woot-input
          v-model.trim="pbxUser"
          class="intertel--uc--input"
          :class="{
            error: $v.pbxUser.$error || connectionStatus === false,
          }"
          :label="$t('INBOX_MGMT.ADD.INTERTEL_UC_CHANNEL.PBX_USER.TITLE')"
          :placeholder="
            $t('INBOX_MGMT.ADD.INTERTEL_UC_CHANNEL.PBX_USER.PLACEHOLDER')
          "
          error=""
          @input="onChangePbxData"
          @blur="$v.pbxUser.$touch"
        />
      </div>
      <div v-if="pbx === 'grandstream'" class="medium-8 columns">
        <woot-input
          v-model.trim="pbxPassword"
          type="password"
          class="intertel--uc--input"
          :class="{
            error: $v.pbxPassword.$error || connectionStatus === false,
          }"
          :label="$t('INBOX_MGMT.ADD.INTERTEL_UC_CHANNEL.PBX_PASSWORD.TITLE')"
          :placeholder="
            $t('INBOX_MGMT.ADD.INTERTEL_UC_CHANNEL.PBX_PASSWORD.PLACEHOLDER')
          "
          error=""
          @input="onChangePbxData"
          @blur="$v.pbxPassword.$touch"
        />
      </div>
      <div
        v-if="pbx === '3cx' || pbx === 'grandstream'"
        class="medium-12 columns"
      >
        <woot-button
          :is-loading="isCheckConnection"
          type="button"
          :variant="getVariantClass"
          :is-disabled="
            pbxUrl === '' ||
              (pbx === 'grandstream' && pbxUser === '') ||
              (pbx === 'grandstream' && pbxPassword === '') ||
              connectionStatus
          "
          class="button success"
          @click="checkConnection"
        >
          {{ $t('INBOX_MGMT.ADD.INTERTEL_UC_CHANNEL.TEST_CONNECTION_BUTTON') }}
        </woot-button>
      </div>
      <div class="medium-12 columns section--button">
        <woot-submit-button
          :loading="uiFlags.isCreating"
          :disabled="
            $v.channelName.$invalid ||
              $v.pbx.$invalid ||
              (pbx === 'api' && $v.webhookCall.$invalid) ||
              (pbx === 'api' && $v.events.$invalid) ||
              ((pbx === '3cx' || pbx === 'grandstream') &&
                $v.pbxUrl.$invalid) ||
              (pbx === 'grandstream' && $v.pbxUser.$invalid) ||
              (pbx === 'grandstream' && $v.pbxPassword.$invalid) ||
              ((pbx === '3cx' || pbx === 'grandstream') && !connectionStatus)
          "
          :button-text="$t('INBOX_MGMT.ADD.INTERTEL_UC_CHANNEL.SUBMIT_BUTTON')"
        />
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import inboxMixin from 'shared/mixins/inboxMixin';
import { additionalUrlValidators } from 'shared/helpers/Validators';
import {
  required,
  maxLength,
  url as urlValidator,
  requiredIf,
} from 'vuelidate/lib/validators';
import router from '../../../../index';
import PageHeader from '../../SettingsSubPageHeader';

/**
 * Eventos soportados por el webhook_call para Intertel UC
 */
const SUPPORTED_WEBHOOK_EVENTS = ['call.started', 'call.ended'];

export default {
  components: {
    PageHeader,
  },
  mixins: [alertMixin, inboxMixin],
  data() {
    return {
      channelName: '',
      pbx: '',
      webhookCall: '',
      events: [],
      supportedWebhookEvents: SUPPORTED_WEBHOOK_EVENTS,
      pbxUrl: '',
      pbxUser: '',
      pbxPassword: '',
      connectionStatus: undefined,
      isCheckConnection: false,
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'inboxes/getUIFlags',
    }),
    /**
     * Obtenemos la clase para el boton de comprobar conexion
     */
    getVariantClass() {
      return 'button--check';
    },
  },
  validations: {
    channelName: { required, maxLength: maxLength(30) },
    pbx: {
      required,
    },
    webhookCall: {
      required: requiredIf(function() {
        return this.pbx === 'api';
      }),
      urlValidator,
      additionalUrlValidators,
    },
    events: {
      required: requiredIf(function() {
        return this.pbx === 'api';
      }),
    },
    pbxUrl: {
      required: requiredIf(function() {
        return this.pbx === '3cx' || this.pbx === 'grandstream';
      }),
    },
    pbxUser: {
      required: requiredIf(function() {
        return this.pbx === 'grandstream';
      }),
    },
    pbxPassword: {
      required: requiredIf(function() {
        return this.pbx === 'grandstream';
      }),
    },
  },
  methods: {
    /**
     * Crear el canal
     */
    async createChannel() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      try {
        let payload = {
          name: this.channelName,
          channel: {
            type: 'intertel_uc',
            pbx: this.pbx,
          },
        };

        if (this.pbx === 'api') {
          payload.channel.webhook_call = this.webhookCall;
          payload.channel.events = this.events;
        }

        if (this.pbx === '3cx') {
          payload.channel.additional_attributes = {
            pbx_url: this.pbxUrl,
            api_key: '',
          };
        }

        if (this.pbx === 'grandstream') {
          payload.channel.additional_attributes = {
            pbx_url: this.pbxUrl,
            pbx_user: this.pbxUser,
            pbx_password: this.pbxPassword,
          };
        }

        const intertelucChannel = await this.$store.dispatch(
          'inboxes/createChannel',
          payload
        );

        router.replace({
          name: 'settings_inboxes_add_agents',
          params: {
            page: 'new',
            inbox_id: intertelucChannel.id,
          },
        });
      } catch (error) {
        this.showAlert(
          this.$t('INBOX_MGMT.ADD.INTERTEL_UC_CHANNEL.API.ERROR_MESSAGE')
        );
      }
    },
    /**
     * Probamos conexion al servidor del conmutador
     */
    async checkConnection() {
      this.isCheckConnection = true;
      try {
        const response = await this.$store.dispatch('inboxes/checkConnection', {
          pbx: this.pbx,
          pbx_url: this.pbxUrl,
          pbx_user: this.pbxUser,
          pbx_password: this.pbxPassword,
        });

        if (response.status === 200) {
          this.connectionStatus = true;
          this.showAlertSuccess(
            this.$t(
              'INBOX_MGMT.ADD.INTERTEL_UC_CHANNEL.TEST_CONNECTION_SUCCESS'
            )
          );
        } else {
          this.connectionStatus = false;
          this.showAlertError(
            this.$t('INBOX_MGMT.ADD.INTERTEL_UC_CHANNEL.TEST_CONNECTION_ERROR')
          );
        }
      } catch (error) {
        this.connectionStatus = false;
        this.showAlertError(
          this.$t('INBOX_MGMT.ADD.INTERTEL_UC_CHANNEL.TEST_CONNECTION_ERROR')
        );
      }

      this.isCheckConnection = false;
    },
    /**
     * Establecemos el status de la conexion con el pbx como undefined para que se vuelva a probar conexion debido a cambio de informacion
     */
    async onChangePbxData() {
      this.connectionStatus = undefined;
    },
  },
};
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/variables';

.section--item {
  padding-top: 20px;
}

.section--button {
  padding-top: 40px;
}

.pbx--type--select {
  margin-bottom: 0px;
}

.pbx-not-selected {
  color: $conversa2-neutral-300-color;
}

.pbx-type-option {
  color: $color-body;
}

.intertel--uc--input {
  padding-bottom: 4px;
}

.checkbox--list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  padding-bottom: 0;

  .checkbox--item {
    display: flex;
    align-items: center;
    gap: 8px;
    align-self: stretch;

    input[type='checkbox'] {
      height: 20px;
      width: 20px;
    }
  }

  label,
  span {
    color: var(--conversa2-neutral-800-color);
    font-size: var(--font-size-small);
    line-height: var(--font-size-medium);
  }

  div[disabled] {
    pointer-events: none;
  }

  span[disabled] {
    pointer-events: none;
    color: var(--conversa2-neutral-300-color);
  }
}

.button--check:hover {
  background-color: #d75400;
}
</style>
